<template>
  <div>
    <h2 class="p-t-5 text-center">在刊信息统计</h2>
    <Row class="text-center p-t-5 p-b-10">
      <i-col span="24">
        <DatePicker size="small" v-model="searchStartDate" transfer :options="formatMaxDate" type="date" format="yyyy-MM-dd"  :clearable="false" placeholder="选择日期" style="width: 210px"></DatePicker>
      </i-col>
    </Row>

    <div style="width:290px;height:180px;" id="countIssueNumberReport"></div>
    <Table class="maintainTable m-t-10" size="small" :columns="countIssueNumberColumn" :data="countIssueNumberData" :row-class-name="rowClassName"
      @on-row-click="handleClickRow"></Table>

    <h2 class="p-t-20 text-center">在刊品牌列表</h2>
    <Row class="table-title p-l-10 p-r-10 m-t-5">
      <i-col span="16">品牌名称</i-col>
      <i-col span="8" class="text-center">在刊点位数</i-col>
    </Row>
    <Row v-for="(item,index) in realTimeBrandData" :key="'r'+index" class="p-t-5 p-l-10 p-r-10">
      <i-col span="16">{{item.name}}</i-col>
      <i-col span="8" class="text-center">{{item.number}}</i-col>
    </Row>
    <div v-show="showArray.length>currentPageSize" class="paging_style">
        <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { GetDateStr, ParseDate } from '@/utils/dateFormat'
import { countIssueNumberGbFeature, countIssueNumberGb } from '@/api/dw/maintain'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      dataSourcePie: null,
      searchStartDate: GetDateStr(Date.now(), 0),
      formatMaxDate: {
        disabledDate (date) {
          return date.valueOf() > Date.now() + 86400000
        }
      },

      countIssueNumberColumn: [
        { title: '类型', key: 'name' },
        { title: '发布点位数', align: 'center', key: 'number' },
        { title: '在刊品牌数', align: 'center', key: 'value' }
      ],
      countIssueNumberData: [],

      chooseRightTableId: null,
      realTimeBrandData: [],
      showArray: [],
      currentPageSize: 18,
      currentPageNumber: 1
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.chooseRightTableId = null
      this.loadIssueNumberGbFeature()
      this.loadBrandList()
    },
    loadIssueNumberGbFeature () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        date: ParseDate(this.searchStartDate)
      }
      countIssueNumberGbFeature(queryModel).then(res => {
        this.countIssueNumberData = res
        const reportData = res.map(element => {
          return {
            value: element.rate,
            name: element.name
          }
        })
        this.initChart(reportData)
      })
    },
    initChart (reportData) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('countIssueNumberReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('countIssueNumberReport'))
          const option = {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '在刊资源占比分布',
                type: 'pie',
                radius: ['70%', '90%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '15',
                    formatter: '{d}%\n{b}',
                    color: '#fff'
                  }
                },
                labelLine: {
                  show: false
                },
                data: reportData
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)

          // 默认选中第一项
          let index = 0
          const _self = this
          _self.dataSourcePie.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: 0
          }) // 设置默认选中高亮部分
          _self.dataSourcePie.on('mouseover', function (e) {
            if (e.dataIndex !== index) {
              _self.dataSourcePie.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: index
              })
            }
          })
          _self.dataSourcePie.on('mouseout', function (e) {
            index = e.dataIndex
            _self.dataSourcePie.dispatchAction({
              type: 'highlight',
              seriesIndex: 0,
              dataIndex: e.dataIndex
            })
          })
        }
      })
    },
    rowClassName (row, index) {
      return row.id === this.chooseRightTableId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow (params, index) {
      this.chooseRightTableId = (this.chooseRightTableId === params.id) ? null : params.id
      this.loadBrandList()
    },
    loadBrandList () {
      const queryModel1 = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        contractFeature: this.chooseRightTableId,
        date: ParseDate(this.searchStartDate),
        gbType: '2' // 统计纬度 1:行业; 2:品牌
      }

      countIssueNumberGb(queryModel1).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.realTimeBrandData = newArray
    }
  },
  computed: {
    companyId () {
      return this.$store.state.situationWeb.companyId
    }
  },
  watch: {
    companyId () {
      this.initPageData()
    },
    searchStartDate () {
      this.initPageData()
    }
  }
}
</script>

<style>
.maintainTable .ivu-table-cell{
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
